import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useTradeaboatStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      bannerCase: file(
        relativePath: { eq: "case-study/tradeaboat/marketplace-for-boats.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredOne: file(
        relativePath: {
          eq: "case-study/tradeaboat/pwa-for-boat-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredTwo: file(
        relativePath: {
          eq: "case-study/tradeaboat/seo-for-boat-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredThree: file(
        relativePath: {
          eq: "case-study/tradeaboat/third-party-integrations.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFour: file(
        relativePath: { eq: "case-study/tradeaboat/user-friendly-search.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredFive: file(
        relativePath: {
          eq: "case-study/tradeaboat/product-card-for-boat-marketplace.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSix: file(
        relativePath: { eq: "case-study/tradeaboat/simple-ads-creation.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredSeven: file(
        relativePath: { eq: "case-study/tradeaboat/promotion-for-sellers.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredEight: file(
        relativePath: {
          eq: "case-study/tradeaboat/dashboard-for-tradeaboat.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      solutionDeliveredNine: file(
        relativePath: { eq: "case-study/tradeaboat/redesign-of-tradeaboat.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      clientVideoReview: file(
        relativePath: { eq: "case-study/tradervs/client-video-review.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      videoCaseImage: file(
        relativePath: { eq: "case-study/tradeaboat/video-case.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)
  return query
}

export default useTradeaboatStaticQuery
