// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bz_kr";
export var caseStudyBackground__lineColor = "bz_kp";
export var caseStudyHead = "bz_kj";
export var caseStudyHead__imageWrapper = "bz_kk";
export var caseStudyProjectsSection = "bz_ks";
export var caseStudyQuote__bgLight = "bz_kt";
export var caseStudyQuote__bgRing = "bz_km";
export var caseStudyVideoReview = "bz_lK";
export var caseStudyVideoReview__bgRing = "bz_lL";
export var caseStudyVideo__ring = "bz_kv";
export var caseStudy__bgDark = "bz_kh";
export var caseStudy__bgLight = "bz_kg";
export var caseStudy__bgLightReverse = "bz_lJ";